<template>

    <div class="collections" v-if="this.collections.length>0">
        <span class="title">Die Sehenswürdigkeit ist Teil dieser KulturSpuren:</span>
        <div class="horizontal-scroll">
            <CardKultour v-for="collection in collections" :key="collection.id" class="collection-card" :id="'horizontal-collection-'+collection.id" :collection="collection" :isSmall="true" marginBottom="20px" marginRight='20px'/>
        </div>
    </div>

</template>
<script>
    import Resource from '@/api/resource';
    import { getAppCollections } from '@/api/collection';
    import { showLoader, hideLoader } from '@/utils/helpers';

    const contentResource = new Resource('contents');

    export default {
        name: "AttractionCollection",
        components: {
            CardKultour: () =>import('@/components/cards/CardKultour.vue'),
        },
        props: {
            collectionIds: {
                default: null,
                required: true,
            },
        },
        data(){
            return{
                collections: [],
            }
        },
        created() {
            if(this.collectionIds !== null){
                if(Array.isArray(this.collectionIds)) {
                    for(var i = 0; i<this.collectionIds.length; i++){
                      this.getCollections(this.collectionIds[i]);
                  }
              }
              else{
                this.getCollections(this.collectionIds)
            }
        }
    },
    computed: {
    },
    methods: {
        showLoader, 
        hideLoader,
        async getCollections(id) {
            await contentResource.get(id)
            .then(response => {
              var data = response.data;
              this.collections.push(data);
          })
            .catch(error => {
              console.log(error);

              this.$notify({
                  duration: 2500,
                  title: 'Fehler beim Laden der Inhalte!',
                  text: 'Die KulTouren konnten nicht geladen werden. Die Verbindung zum Server wurde unterbrochen.'
              });

          })
        },
    },
}
</script>

<style lang="scss" scoped>

    .collections{

        .title{
            color: #000;
            font-size: 16px;
            font-weight: 700;
            font-family: 'elliot-bold', sans-serif;
        }

        .horizontal-scroll{
            display: flex;
            flex-direction: row;
            margin: 0px -10px;
            padding: 0px 10px;
            margin-top: 15px;
            overflow-x: auto;
        }

        .collection-card{
            max-width: 320px;
            width: 320px !important;
            margin-right: 15px !important;

            @media(max-width:991px){
                width: auto !important;

                &:last-child{
                    margin-right: 0 !important;
                }
            }
        }
    }

</style>
